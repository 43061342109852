import {useInmemoriServices} from '@services'
import {useMutation} from '@tanstack/react-query'
import {Alert, Button, Form, Input} from 'antd'
import {AxiosError} from 'axios'

interface LoginComponentProps {
  redirect?: string
}

const LoginComponent = ({redirect}: LoginComponentProps) => {
  const inmemoriServices = useInmemoriServices()

  const useLoginMutation = useMutation({
    mutationFn: async ({email, password}: {email: string; password: string}) =>
      inmemoriServices.authService.login(email, password),
    onSuccess: () => {
      if (redirect) window.location.href = redirect
    }
  })

  const onFinish = (loginInfos: {email: string; password: string}) => {
    useLoginMutation.mutate(loginInfos)
  }

  const onFinishFailed = () => {
    useLoginMutation.reset()
  }

  return (
    <Form
      name='basic'
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 600, marginTop: '2rem'}}
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'>
      <Form.Item
        label='Email'
        name='email'
        rules={[
          {required: true, message: 'Veuillez rentrer votre email'},
          {type: 'email', message: 'Veuillez rentrer un email valide'}
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{required: true, message: 'Veuillez rentrer votre mot de passe'}]}>
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        <Button type='primary' htmlType='submit' loading={useLoginMutation.isPending}>
          Valider
        </Button>
      </Form.Item>

      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        {useLoginMutation.isSuccess && (
          <Alert message='Vous êtes maintenant connecté(e)' type='success'></Alert>
        )}
        {useLoginMutation.isError && (
          <Alert
            message={
              useLoginMutation.error instanceof AxiosError &&
              useLoginMutation.error?.response?.status === 401
                ? 'Mot de passe ou email erroné'
                : 'Une erreur est survenue'
            }
            description={
              !(useLoginMutation.error instanceof AxiosError) &&
              JSON.stringify(useLoginMutation.error)
            }
            type='error'></Alert>
        )}
      </Form.Item>
    </Form>
  )
}

export default LoginComponent
