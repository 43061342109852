import LogoutComponent from '@components/auth/logout/logout'
import {ReactNode} from 'react'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {useApp} from '@store/app'
import HouseSwitch from './houseSwitch'
import {Avatar} from 'antd'
export interface MenuItemProps {
  label?: string | ReactNode
  key: string
  keyPlural?: string
  icon?: IconProp
  children?: MenuItemProps[]
  directPath?: boolean
  isStatic?: boolean
}

export const useNavItems = (): MenuItemProps[] => {
  const {t, $profile} = useApp()
  return [
    {
      key: 'project'
    },
    {
      label: t('navBar.ressource.label'),
      key: 'ressource',
      children: [
        {
          key: 'house'
        },
        {
          key: 'category'
        },
        {
          key: 'product'
        },
        {
          key: 'supplier'
        },
        {
          key: 'place'
        },
        {
          key: 'serviceType',
          label: 'Services'
        },
        {
          key: 'masterDocument',
          label: 'Documents'
        },
        {
          key: 'communicationTemplate',
          label: 'Communications'
        },
        {
          key: 'tag'
        },
        {
          key: 'sector'
        },
        {
          key: 'option'
        },
        {
          key: 'download'
        },
        {
          key: 'catalog',
          directPath: true,
          label: t('navBar.ressource.children.catalog.label'),
          icon: 'bag-shopping'
        }
      ]
    },
    {
      label: t('navBar.erp.label'),
      key: 'erp',
      children: [
        {
          key: 'domain'
        },
        {
          key: 'form'
        },
        {
          key: 'select'
        },
        {
          key: 'table'
        },
        {
          key: 'permission'
        },
        {
          key: 'snippet'
        },
        {
          key: 'sequence'
        },
        {
          key: 'engine'
        },
        {
          key: 'team'
        },
        {
          key: 'merge'
        },
        {
          key: 'import'
        }
      ]
    },
    {
      label: <Avatar style={{backgroundColor: '#606266'}}>{$profile?.initials}</Avatar>,
      key: 'profileMenu',
      children: [
        {
          label: <HouseSwitch />,
          key: 'houseSwitch',
          isStatic: true
        },
        {
          label: t('navBar.profileMenu.children.profile'),
          key: `domain/profiles?mainId=${$profile?._id}`,
          directPath: true
        },
        {
          label: <LogoutComponent />,
          key: 'profileDropdown'
        }
      ]
    }
  ]
}
