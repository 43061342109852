import {ReactElement} from 'react'

import LoginComponent from '@components/login'
import Layout, {NextPageWithLayout} from '@layouts/layout'
import {useRouter} from 'next/router'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {GetStaticProps} from 'next'

const Page: NextPageWithLayout = () => {
  const {
    query: {redirect}
  } = useRouter()

  const redirectTo = typeof redirect === 'string' ? redirect : '/'

  return <LoginComponent redirect={redirectTo} />
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export const getStaticProps: GetStaticProps = async () => {
  const props = await serverSideTranslations('fr', ['common'])
  return {props}
}

export default Page
